class ConsultaDadosAnttController{
    constructor(document, MonitriipApiService, LogCsv, LogCabecalhoCSV, dateUtil, alertService, MascaraEntrada, NumberUtil){
        this._document = document[0];
        this.monitriipApi =  MonitriipApiService;
        this._logCsv = LogCsv;  
        this._logCabecalhoCSV = LogCabecalhoCSV;
        this._dateUtil = dateUtil;
        this._alertService = alertService;
        this._mascaraEntrada = MascaraEntrada;
        this._numberUtil = NumberUtil;      
        this._inicializar();     
    }
    
    _inicializar(){
        this._seletor = document.getElementById.bind(document);        
        this._carregarTiposEvento();        
        this.pesquisa = {}; 
        this._resetarFilter();      
    }

    _ElementDocument(){
        this.pesquisa.dataInicial = this._dateUtil.formatarIsoDatePattern(this._seletor('dataInicial').objetoSelecionado);
        this.pesquisa.dataFinal = this._dateUtil.formatarIsoDatePattern(this._seletor('dataFinal').objetoSelecionado);
        this.pesquisa.idHoraInicial = this._seletor('idHoraInicial').objetoSelecionado;
        this.pesquisa.idHoraFinal = this._seletor('idHoraFinal').objetoSelecionado;
    }

   
    ordenar(keyname){
        
        this.sortKey = keyname;
        this.reverse = !this.reverse;

        //Adicionando os icones de ordenação

        if(this.reverse == true){
            this._document.querySelector('#ordenar'+this.sortKey).classList.add('fa-sort-desc');
            this._document.querySelector('#ordenar'+this.sortKey).classList.remove('fa-sort-asc');
            
        }else if(this.reverse == false){
            this._document.querySelector('#ordenar'+this.sortKey).classList.add('fa-sort-asc');
            this._document.querySelector('#ordenar'+this.sortKey).classList.remove('fa-sort-desc');
        }
        
        if(this.sortKey == 'dataHoraFormatada'){
            this._document.querySelector('#ordenarevento').classList.remove('fa-sort-asc', 'fa-sort-desc');
        }else if(this.sortKey == 'evento' ){
            this._document.querySelector('#ordenardataHoraFormatada').classList.remove('fa-sort-asc', 'fa-sort-desc');
        }      
    };

    _mesclarDataEHora(data,hora){
        let pattern = "hh:mm";        
        let dataClone = moment(data).clone().toDate();
        dataClone.setHours(moment(hora,pattern).get('hour'));
        dataClone.setMinutes(moment(hora,pattern).get('minute'));      
        return dataClone;
    }

    _dataHoraInicialMenorOuIgualADataHoraFinal(){
        
        this._ElementDocument();        
        let dataInicial = this._mesclarDataEHora(this.pesquisa.dataInicial,this.pesquisa.idHoraInicial);
        let dataFinal = this._mesclarDataEHora(this.pesquisa.dataFinal,this.pesquisa.idHoraFinal);
        return dataInicial.getTime() <= dataFinal.getTime();
    }

    _dataFutura(){
        
        this._ElementDocument();
        
        let dataFutura;
        let dataAtual = new Date();     
        let dataInicial = this._mesclarDataEHora(this.pesquisa.dataInicial,this.pesquisa.idHoraInicial);
        let dataFinal = this._mesclarDataEHora(this.pesquisa.dataFinal,this.pesquisa.idHoraFinal);

        if(dataInicial)
            dataFutura = moment(dataInicial).diff(dataAtual, 'days');
        
        if(dataFinal)
            dataFutura = moment(dataFinal).diff(dataAtual, 'days');
       
        return dataFutura;
    }

   

    _ultrapassaLimiteDe72Horas(){  
        this._ElementDocument();      
        let dataInicial = this._mesclarDataEHora(this.pesquisa.dataInicial,this.pesquisa.idHoraInicial);
        let dataFinal = this._mesclarDataEHora(this.pesquisa.dataFinal,this.pesquisa.idHoraFinal);

        return moment(dataFinal).diff(dataInicial,'second') > 259200;
    }

    _parametrosDeValidacao(){
        
        this._ElementDocument();
        
        if(this.eventoSelecionado.id > 0 && this.eventoSelecionado.id < 11 && this.pesquisa.veiculoPlaca == '') {
            this._alertService.exibirAlert("A placa do veículo é obrigatória!", this);
            this.loading = false; 
            return false;
        }

        if(!this.pesquisa.dataInicial){
          
          this._alertService.exibirAlert("A data inicial é obrigatória!", this);
          this.loading = false; 
          return false;
        }

        if(!this.pesquisa.dataFinal){
         
          this._alertService.exibirAlert("A data final é obrigatória!", this);
          this.loading = false; 
          return false;
        }

        if(!this.pesquisa.idHoraInicial){
          
          this._alertService.exibirAlert("A hora inicial é obrigatória!", this);
          this.loading = false; 
          
          return false;
        }

        if(!this.pesquisa.idHoraFinal){
          
          this._alertService.exibirAlert("A hora final é obrigatória!", this);
          this.loading = false; 
          return false;
        }
        
        if(this._dataFutura() > 0){
            this._alertService.exibirAlert("Não são permitidas datas futuras!", this);
            this.loading = false; 
            return false;
        }

        if(!this._dataHoraInicialMenorOuIgualADataHoraFinal()){
         
          this._alertService.exibirAlert("O perído informado é inválido. A data e hora inicial deve ser menor ou igual a data e hora final!", this);
          this.loading = false; 
          return false;
        }

        if(this._ultrapassaLimiteDe72Horas()){
          this._alertService.exibirAlert("O perído informado ultrapassa o limite de 72 horas!", this);
          this.loading = false; 
          return false;
        }      

        return true;
    }

    _validarParametrosDeConsulta(){
        this._ElementDocument();
        if(!this._parametrosDeValidacao())
            return false;
        
        return true;
    }

   
    _resetarFilter(){                  
        this.pesquisa.veiculoPlaca = '';
        this.eventoSelecionado = this.tiposEvento[0];
        this._setarDataAtual(); 
        this._setarHoraDefault();
      };

    _consultarLogs(){       
        
        this._ElementDocument();

        if(!this._validarParametrosDeConsulta())
          return;      

        let dataInicial = this._mesclarDataEHora(this.pesquisa.dataInicial,this.pesquisa.idHoraInicial);
        let dataFinal = this._mesclarDataEHora(this.pesquisa.dataFinal,this.pesquisa.idHoraFinal);       
        
        this.loading = true; 

        let consulta = this.monitriipApi.listarLogs(this.pesquisa.veiculoPlaca ?  this.pesquisa.veiculoPlaca : null, dataInicial, dataFinal, this.eventoSelecionado ? this.eventoSelecionado.id :  null);

        
        this.promise = consulta
          .then((logs) => {
            
            if(!logs.length){             
                this._alertService.exibirAlert("Nenhum registro encontrado", this);
                this.loading = false; 
            }
            
            this.loading = false;           
            this.logsList = logs;
            
     
          })
          .catch(
            erro => this._tratarErroRetornado(erro,"Ocorreu um erro ao consultar os logs")       
          )      
    }


    _tratarErroRetornado(erro,mensagem){
        this._alertService.exibirAlert(mensagem,this);
        this.loading = false; 
    }

    abrirPopup(log){
  
        this.logSelecionado = log;

        /* Formatação  dos Logs */
        this.dataHoraCancelamento = this._dateUtil.formatarDataHora(this.logSelecionado.dataHoraCancelamento);    

        this.cnpjEmpresa = this._mascaraEntrada.formatarCNPJ(this.logSelecionado.cnpjEmpresa);

        this.dataHoraViagem = this._dateUtil.agregarDataHora(this.logSelecionado.dataViagem, this.logSelecionado.horaViagem);

        this.dataHoraEmissaoBilhete = this._dateUtil.agregarDataHora(this.logSelecionado.dataEmissaoBilhete, this.logSelecionado.horaEmissaoBilhete);

        this.valorTarifa = 'R$ ' +this._numberUtil.formatarMoeda(this.logSelecionado.valorTarifa);
        this.valorPedagio = 'R$ ' + this._numberUtil.formatarMoeda(this.logSelecionado.valorPedagio);
        this.valorTaxaEmbarque = 'R$ ' +this._numberUtil.formatarMoeda(this.logSelecionado.valorTaxaEmbarque);
        this.valorTotal = 'R$ ' +this._numberUtil.formatarMoeda(this.logSelecionado.valorTotal);   

        this.cnpjEmpresaTransporte = this._mascaraEntrada.formatarCNPJ(this.logSelecionado.cnpjEmpresaTransporte);
        this.dataHoraAberturaPorta = this._dateUtil.formatarDataHora(this.logSelecionado.dataHoraAberturaPorta);
        
        this.dataHoraProgramadaViagem = this._dateUtil.agregarDataHora(this.logSelecionado.dataProgramadaViagem, this.logSelecionado.horaProgramadaViagem); 

        /* Animação do Popup */
        this._document.querySelector('#popup'+log.idLog).classList.remove('hidden');
        this._document.querySelector('#popup'+log.idLog+'Content').classList.add('flip-horizontal--active');  
        
        $(".popup__content__close2").click(function(){
            $(".popup__content").removeClass("flip-horizontal--active");
            $(".popup").addClass("hidden");
        });

    }

    gerarCsv(){        
        return this._logCsv.gerarCSV(this.logsList);         
    };
      
    gerarCabecalho(){        
        
        return this._logCabecalhoCSV.gerarCabecalho(this.logsList[0].idLog);
    };  

    _carregarTiposEvento(){
        this.tiposEvento = [   
            {nome:'Log de Detector de Parada',id:6},   
            {nome:'Log de Início/Fim de Viagem Fretamento',id:8}, 
            {nome:'Log de Início/Fim de Viagem Regular',id:7}, 
            {nome:'Log de Jornada de Trabalho do Motorista',id:5},
            {nome:'Log de Velocidade Tempo e Localização',id:4},           
            {nome:'Log de Venda de Passagens',id:0}, 
            {nome:'Log de Venda de Passagens Canceladas',id:11},          
            {nome:'Log do Leitor de Bilhete de Embarque',id:9}           
            
            //Logs Não utilizados no momento
             ////{nome:'Todos os Logs',id:null},   
            // {nome:'Log de Cartões Emitidos & Recargas Efetuadas',id:1},
            // {nome:'Log de Registro de Ocorrências Rodoviário',id:2},
            // {nome:'Log de Registro de Ocorrências Semiurbano',id:3},
            //{nome:'Log do Leitor de Cartão RFID',id:10},
          ];    
          
          
    }

    _obterDataSelecionada(){
        let data = new Date(); 
        return this._dateUtil.formatarParaPtBr(data); 
    }

     _setarDataAtual(){
        let dataInicial =  this._document.getElementById("dataInicial");
        dataInicial.objetoSelecionado = this._obterDataSelecionada(); 
       
        let dataFinal =  this._document.getElementById("dataFinal");
        dataFinal.objetoSelecionado = this._obterDataSelecionada(); 
    }

    _setarHoraDefault(){
        let horaInicial =  this._document.getElementById("idHoraInicial");
        horaInicial.objetoSelecionado = "00:00";
        horaInicial.hour = "00";
        horaInicial.min = "00"; 
       
        let horaFinal =  this._document.getElementById("idHoraFinal");
        horaFinal.objetoSelecionado = "23:59";
        horaFinal.hour = "23";
        horaFinal.min = "59";

    }
   
};

ConsultaDadosAnttController.$inject = [
    "$document", 
    "MonitriipApiService", 
    "LogCsv",
    "LogCabecalhoCSV",
    "DateUtil",
    "AlertService",
    "MascaraEntrada",
    "NumberUtil"

]; 

angular.module('monitriip-web')
        .controller('ConsultaDadosAnttController', ConsultaDadosAnttController);
